import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import Header from "../components/header";
import Seo from "../components/seo";

// markup
const HiyoriteiPage = () => {
  return (
    <div>
      <Seo title="HIYORITEI - 日和亭" />
      <main id="wrap">
        <Header />
        <article className="main-col" role="main">
          <div className="main-wrap">
            <div className="key-visual">
              <div className="main-img">
                <div className="img">
                  <StaticImage
                    layout="fullWidth"
                    alt="日和亭メイン画像"
                    src="../images/hiyoritei/mv.jpg"
                    className="pc"
                  />
                  <StaticImage
                    layout="fullWidth"
                    alt="日和亭メイン画像"
                    src="../images/hiyoritei/mv-sp.jpg"
                    className="sp"
                  />
                  <div className="logo-sp sp">
                    <StaticImage
                      layout="constrained"
                      alt="日和亭"
                      src="../images/hiyoritei/logo.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="c-logo-exp l-flex">
              <div className="img logo pc">
                <StaticImage
                  layout="constrained"
                  alt="ヒヨリベーカリー＆カフェ"
                  src="../images/hiyoritei/logo.svg"
                />
              </div>
              <div className="text">
                <p>
                  「日和亭」は、大正時代に西洋文化をいち早く取り入れた小幡洋食部の進取の気風を受け継ぎ、伝統的なレシピの中に新たなエスプリを込めて温故知新の一皿にしてご提供いたします。高品質なプレミアムクラスのデュラム小麦のセモリナ、食塩だけを原料に、イタリア伝統のパスタ作りで小麦の風味と味を最大限に引き出した無添加の生パスタを、平田牧場金華豚・三元豚や海の幸を活かしたオリジナルソースのスパゲッティや、和を基調とした季節毎の甘味をお楽しみいただけます。
                </p>
              </div>
            </div>
          </div>
          <section>
            <div className="c-pages thums l-flex">
              <StaticImage
                layout="constrained"
                alt="あんみつ"
                src="../images/hiyoritei/thum01.jpg"
              />
              <StaticImage
                layout="constrained"
                alt="フルーツロール"
                src="../images/hiyoritei/thum02.jpg"
              />
              <StaticImage
                layout="constrained"
                alt="パスタ１"
                src="../images/hiyoritei/thum03.jpg"
              />
              <StaticImage
                layout="constrained"
                alt="パスタ２"
                src="../images/hiyoritei/thum04.jpg"
              />
              <StaticImage
                layout="constrained"
                alt="店内"
                src="../images/hiyoritei/thum05.jpg"
              />
              <StaticImage
                layout="constrained"
                alt="ぜんざい"
                src="../images/hiyoritei/thum06.jpg"
              />
            </div>
            <p className="c-pages-caution sans before-btn">
              ※メニューは一例です。
              季節や仕入れ状況により変更になる場合がございます。あらかじめご了承ください。
            </p>
            {/* <div className="c-pages btn hiyoritei l-flex cont-center">
              <Link to="../images/hiyoritei-lunch-menu.pdf" target="_blank">
                日和亭ランチメニューPDF
              </Link>
              <Link to="../images/hiyoritei-dinner-menu.pdf" target="_blank" className="bg">
                日和亭ディナーメニューPDF
              </Link>
            </div> */}
          </section>

          <footer>
            <div className="c-footer__wrap">
              <ul>
                <li>
                  <Link className="menu-item store-info" to="/">
                    日和亭 小幡楼
                  </Link>
                </li>
                <li>
                  <Link className="menu-item store-info" to="/bakerycafe/">
                    ヒヨリベーカリー＆カフェ
                  </Link>
                </li>
                <li>
                  <Link className="menu-item store-info" to="/hiyoritei/">
                    日和亭
                  </Link>
                </li>
                <li>
                  <Link className="menu-item store-info" to="/access/">
                    アクセス
                  </Link>
                </li>
                <li>
                  <Link className="menu-item store-info" to="/history/">
                    割烹小幡の歴史
                  </Link>
                </li>
              </ul>
            </div>
            <small className="sans">&copy; 2021 obatarou</small>
          </footer>
        </article>
      </main>
    </div>
  );
};

export default HiyoriteiPage;
